<template>
    <div class="container-fluid mt-4">
        <div class="row align-items-center">
            <div class="col-lg-4 col-sm-2">
                <family-pill v-bind:families=families />
            </div>
        </div>
        <div class="row">
            <div class="col-md-4 mt-4" v-for="pet in selected_family.pets" v-bind:key="pet.id">
                <dashboard-pet-card
                    :icon="getPetIcon(pet)"
                    v-bind:title=pet.name
                    v-bind:description="getBreedName(pet.breed_id)"
                    v-bind:pet="pet"
                />
            </div>
            <div class="col-md-4 mt-4">
                <place-holder-card
                    :title="{ text: 'Добавить питомца', variant: 'h5' }"
                    @click="redirectToPetCreate"
                />
            </div>
        </div>
    </div>
</template>

<script>
import setTooltip from "@/assets/js/tooltip.js";
import FamilyPill from "./components/FamilyPill.vue";
import showSwal from "/src/mixins/showSwal.js";
import PlaceHolderCard from "@/examples/Cards/PlaceHolderCard.vue";
import DashboardPetCard from "@/views/components/DashboardPetCard.vue";

export default {
    name: "Dashboard",
    components: {
        DashboardPetCard,
        PlaceHolderCard,
        FamilyPill,
    },
    mounted() {
        setTooltip();
    },
    data() {
        return {
            "families": [],
            "selected_family": {
                "family": {},
                "pets": [],
            },
            "breed_id_map": {},
        }
    },
    computed: {
        loaded_families() {
            return this.$store.getters["family/my_families"];
        },
        loaded_breed_id_map() {
            return this.$store.getters["animals/breed_id_map"];
        },
    },
    async created() {
        try {
            this.loadBreeds()
        } catch (error) {
            console.log(error)

            showSwal.methods.showSwal({
                type: "error",
                message: "Что-то пошло не так",
            });
        }

        try {
            await this.$store.dispatch("family/my");
            this.families = this.loaded_families.families;
            if (this.families.length > 0) {
                this.selected_family = this.families[0]
            }
        } catch (error) {
            showSwal.methods.showSwal({
                type: "error",
                message: "Что-то пошло не так",
            });
        }
    },
    methods: {
        loadBreeds() {
            if (this.breed_id_map.length > 0) {
                return
            }

            this.$store.dispatch("animals/types");
            this.breed_id_map = this.loaded_breed_id_map;
        },
        getBreedName(breedId) {
            const breed = this.breed_id_map[breedId]

            if (breed) {
                return breed.name
            }

            return "название породы"
        },
        redirectToPetCreate() {
            this.$router.push({
                name: "PetCreate",
                params: {
                    family_id: this.selected_family.family.id,
                }
            })
        },
        getPetIcon(pet) {
            let icon = this.breed_id_map[pet.breed_id]?.type.key
            let background = "bg-gradient-info"
            if (pet.gender === 1) {
                background = "bg-gradient-primary"
            }

            return {
                background: background,
                component: `text-white fas fa-${icon}`
            }
        },
    },
};
</script>
