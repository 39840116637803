<template>
  <div class="card mt-4">
    <div class="card-header pb-0 p-3">
      <h6 class="mb-0">Информация о чипе</h6>
    </div>

    <div class="card-body p-3">
      <div>
        Данные о чипе отсутсвуют. <a @click="toggleEditing" style="text-decoration: underline">Укажите номер чипа</a>
      </div>

      <Form
          role="form"
          class="text-start"
          @submit="setupCheapNumber"
          :validation-schema="schema"
      >

        <div class="row mt-3" v-if="editing">
          <soft-field
              id="cheap_number"
              name="cheap_number"
              v-model="payload.cheap_number"
              type="text"
              placeholder="123456789012345"
          />

          <div class="form-group mb-0">
            <soft-button
              class="my-4 mb-2"
              variant="gradient"
              color="success"
              full-width
          >
            Сохранить
          </soft-button>
          </div>
        </div>

      </Form>
    </div>
  </div>
</template>

<script>
import showSwal from "/src/mixins/showSwal.js";
import SoftField from "@/components/SoftField.vue";
import SoftButton from "@/components/SoftButton.vue";
import { Form } from "vee-validate";
import * as yup from "yup";

export default {
  name: "CheapSetupCard",
  components: {SoftButton, SoftField, Form},

  emits: ["update:setupCheapNumber"],

  props: {
    pet_id: {
      type: String,
    },
  },

  data() {
    const schema = yup.object().shape({
      cheap_number: yup.string()
          .required("Укажите номер чипа")
          .length(15, "Номер чипа должен содержать 15 цифр")
          .matches("^[0-9]*$", "Номер чипа должен содержать только цифры"),
    }, [
        ['cheap_number', 'cheap_number'],
    ]);

    return {
      schema,
      editing: false,
      payload: {
        cheap_number: null,
      },
    }
  },

  methods: {
    toggleEditing() {
      this.editing = !this.editing
    },

    async setupCheapNumber() {
      try {
        await this.$store.dispatch("family/patchPet", {
          id: this.pet_id,
          cheap_number: this.payload.cheap_number,
        });

        this.$emit("update:setupCheapNumber", this.payload.cheap_number)

        this.editing = false
      } catch (error) {
        showSwal.methods.showSwal({
          type: "error",
          message: "Что-то пошло не так",
        });
      }
    },
  },
}
</script>
