<template>
    <div class="container top-0 position-sticky z-index-sticky">
        <div class="row">
            <div class="col-12">
                <navbar
                        is-blur="blur blur-rounded my-3 py-2 start-0 end-0 mx-4 shadow"
                        btn-background="bg-gradient-success"
                        :dark-mode="true"
                />
            </div>
        </div>
    </div>
    <main class="mt-0 main-content main-content-bg">
        <section>
            <div class="page-header min-vh-75">
                <div class="container">
                    <div class="row">
                        <div class="mx-auto col-xl-4 col-lg-5 col-md-6 d-flex flex-column">
                            <div class="mt-8 card card-plain" v-if="registration.state === 0">
                                <div class="pb-0 card-header text-start">
                                    <h3 class="font-weight-bolder text-success text-gradient text-center">
                                        Подтверждаем Вашу почту...
                                    </h3>
                                </div>
                                <div class="card-body pb-3">
                                    <div class="text-center">
                                        <soft-button
                                                class="my-4 mb-2"
                                                variant="gradient"
                                                color="success"
                                                full-width
                                                :is-disabled="loading ? true : false"
                                        >
                        <span
                                v-if="loading"
                                class="spinner-border spinner-border-sm"
                        ></span>
                                            <span v-else>Войти</span>
                                        </soft-button>
                                    </div>
                                </div>
                            </div>

                            <div class="mt-8 card card-plain" v-if="registration.state === 1">
                                <div class="pb-0 card-header text-start">
                                    <h3 class="font-weight-bolder text-warning text-gradient text-center">
                                        Указаны некорректные данные
                                    </h3>
                                </div>
                                <div class="card-body pb-3">
                                    <div class="text-center">
                                        <router-link
                                            :to="{ name: 'Register' }"
                                        >
                                        <soft-button
                                            class="my-4 mb-2"
                                            variant="gradient"
                                            color="success"
                                            full-width
                                        >
                                            Зарегистрироваться еще раз
                                        </soft-button>
                                        </router-link>
                                    </div>
                                </div>
                            </div>

                            <div class="mt-8 card card-plain" v-if="registration.state === 2">
                                <div class="pb-0 card-header text-start">
                                    <h3 class="font-weight-bolder text-success text-gradient text-center">
                                        Ваша почта успешно подтверждена
                                    </h3>
                                </div>
                                <div class="card-body pb-3">
                                    <div class="text-center">
                                        <router-link
                                            :to="{ name: 'Login' }"
                                        >
                                        <soft-button
                                                class="my-4 mb-2"
                                                variant="gradient"
                                                color="success"
                                                full-width
                                        >
                                            Войти
                                        </soft-button>
                                        </router-link>
                                    </div>
                                </div>
                            </div>

                            <div class="mt-8 card card-plain" v-if="registration.state === 3">
                                <div class="pb-0 card-header text-start">
                                    <h3 class="font-weight-bolder text-warning text-gradient text-center">
                                        Срок регистрации истек
                                    </h3>
                                </div>
                                <div class="card-body pb-3">
                                    <div class="text-center">
                                        <router-link
                                            :to="{ name: 'Register' }"
                                        >
                                        <soft-button
                                                class="my-4 mb-2"
                                                variant="gradient"
                                                color="success"
                                                full-width
                                        >
                                            Зарегистрироваться еще раз
                                        </soft-button>
                                        </router-link>
                                    </div>
                                </div>
                            </div>

                            <div class="mt-8 card card-plain" v-if="registration.state === 4">
                                <div class="pb-0 card-header text-start">
                                    <h3 class="font-weight-bolder text-warning text-gradient text-center">
                                        Что-то пошло не так
                                    </h3>
                                </div>
                                <div class="card-body pb-3">
                                    <div class="text-center">
                                        <router-link
                                            :to="{ name: 'Register' }"
                                        >
                                        <soft-button
                                                class="my-4 mb-2"
                                                variant="gradient"
                                                color="success"
                                                full-width
                                        >
                                            Зарегистрироваться еще раз
                                        </soft-button>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="top-0 oblique position-absolute h-100 d-md-block d-none me-n8">
                                <div
                                        class="bg-cover oblique-image position-absolute fixed-top ms-auto h-100 z-index-0 ms-n6"
                                        :style="{
                    backgroundImage:
                      'url(' + require('@/assets/img/bg/login.png') + ')',
                    backgroundPosition: 'center',
                  }"
                                ></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
    <app-footer />
</template>

<script>
import Navbar from "@/examples/PageLayout/Navbar.vue";
import AppFooter from "@/examples/PageLayout/Footer.vue";
import SoftButton from "@/components/SoftButton.vue";
import showSwal from "/src/mixins/showSwal.js";
const body = document.getElementsByTagName("body")[0];
import { mapMutations } from "vuex";

export default {
    name: "RegisterEmailVerify",
    components: {
        Navbar,
        AppFooter,
        SoftButton,
    },

    data() {
        const registration = {
            id: null,
            email: null,
            state: 0,
        };
        return {
            loading: false,
            registration: registration,
        };
    },
    created() {
        this.toggleEveryDisplay();
        this.toggleHideConfig();
        body.classList.remove("bg-gray-100");

        this.registration.id = this.$route.query.id
        this.registration.email = this.$route.query.email
        this.handleVerify()
    },
    beforeUnmount() {
        this.toggleEveryDisplay();
        this.toggleHideConfig();
        body.classList.add("bg-gray-100");
    },
    methods: {
        ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),

        async handleVerify() {
            if (!this.registration.id || !this.registration.email) {
                this.registration.state = 1

                return
            }

            this.loading = true;
            try {
                await this.$store.dispatch("auth/registerEmailVerify", this.registration);
                this.registration.state = 2
            } catch (error) {
                this.loading = false;

                if (error.response === undefined) {
                    showSwal.methods.showSwal({
                        type: "error",
                        message: "Что-то пошло не так",
                    });

                    return
                }

                if (error.response.status === 404) {
                    this.registration.state = 3

                    return
                }

                this.registration.state = 4
            }
        },
    },
};
</script>
