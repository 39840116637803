import axios from "axios";
const API_URL = process.env.VUE_APP_API_BASE_URL + '/web/auth';

export default {
  async login(user) {
    const response = await axios.post(API_URL + "/login", user, {
      withCredentials: true,
    });

    return response.data?.user
  },

  async logout() {
    return axios.post(API_URL + "/logout", {}, {
      withCredentials: true,
    });
  },

  async startRegistration(user) {
    const response = await axios.post(API_URL + "/registration/start", user);

    return response.data
  },

  async registrationEmailVerify(reg) {
    const response = await axios.post(
        API_URL + `/registration/email-verify`,
        reg,
    );

    return response.data
  },

  async startResetPassword(email) {
    await axios.post(
        API_URL + `/reset-password/start`,
        {email},
    );
  },

  async checkResetPassword(reset){
    return axios.post(
        API_URL + `/reset-password/check`,
        reset,
    );
  },

  async confirmResetPassword(payload){
    return axios.post(
        API_URL + `/reset-password/confirm`,
        payload,
    );
  },

  async changePassword(payload) {
    return axios.post(
        API_URL + `/users/change-password`,
        payload,
    )
  },

  async patchUser(payload) {
    return axios.patch(
        API_URL + `/users`,
        payload,
    )
  },
};
