import familyService from "@/services/family.service";

const initialState = { my_families: [] };

export const family = {
  namespaced: true,
  state: initialState,
  actions: {
    async my({ commit }) {
      const response = await familyService.getMyFamilies();
      commit("SET_MY_FAMILIES", response);
    },
    createPet(_, pet) {
      return familyService.createPet(pet);
    },
    patchPet(_, pet) {
      return familyService.patchPet(pet)
    },
    getPet(_, petId) {
      return familyService.getPet(petId)
    },
    deletePet(_, petId) {
      return familyService.deletePet(petId)
    }
  },

  mutations: {
    SET_MY_FAMILIES (state, myFamilies) {
      state.my_families = myFamilies;
    },
  },

  getters: {
    my_families(state) {
      return state.my_families;
    },
  },
};
