<template>
<li>
    <span v-if="node.link">
        <a :href="node.link.url" target="_blank">
            <i class="fab fa-telegram" :class="node.link.icon"></i>
            {{ node.name }}
        </a>
    </span>
    <span v-else>
        {{ node.name }}
    </span>

    <ul v-if="node.children && node.children.length > 0">
        <chat-tree-node :node="c" v-for="c in node.children" :key="c"></chat-tree-node>
    </ul>
</li>
</template>

<script>
export default {
    name: 'ChatTreeNode',
    props: {
        node: {
            type: Object,
            required: true
        },
    },
}
</script>

<style scoped>
.tg-color {
    color: #7ea6f6;
}
.vk-color {
    color: #4C75A3;
}
</style>
