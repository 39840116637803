/**
=========================================================
* Vue Soft UI Dashboard - v3.0.0
=========================================================

* Product Page: https://creative-tim.com/product/vue-soft-ui-dashboard-laravel
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import SoftUIDashboard from "./soft-ui-dashboard";
import axios from "axios";
import {localStorageUserKey} from "@/store/auth.module";
import I18NInstance from './i18n'

axios.defaults.headers.common["Content-Type"] = "application/json"
axios.defaults.withCredentials = true

axios.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        if (error && error.response && error.response.status === 401) {
            console.log("user unauthenticated")
            router.push("/login")
            localStorage.removeItem(localStorageUserKey)
            return
        }

        return Promise.reject(error);
    }
);

const appInstance = createApp(App);
appInstance.use(store);
appInstance.use(router);
appInstance.use(SoftUIDashboard);
appInstance.mount("#app");
appInstance.use(I18NInstance)
