<template>
  <div class="container-fluid">
    <div
      class="mt-4 page-header min-height-300 border-radius-xl"
      :style="{
        backgroundImage:
          'url(' + require('@/assets/img/curved-images/curved14.jpg') + ')',
        backgroundPositionY: '50%',
      }"
    >
      <span class="mask bg-gradient-success opacity-6"></span>
    </div>
    <div class="mx-4 overflow-hidden card card-body blur shadow-blur mt-n6">
      <div class="row gx-4">
        <div class="col-auto">
          <div class="avatar avatar-xl position-relative">
            <img
              src="@/assets/img/bruce-mars.jpg"
              alt="profile_image"
              class="shadow-sm w-100 border-radius-lg"
            />
          </div>
        </div>
        <div class="col-auto my-auto">
          <div class="h-100">
            <h5 class="mb-1">{{ pet.name }}</h5>
            <p class="mb-0 text-sm font-weight-bold">
              <span v-if="breed_name">
                {{ breed_name }},
              </span>

              <span v-if="pet.age">
                {{ $tc('years', pet.age.years ) }}
                {{ $tc('months', pet.age.months ) }}
              </span>
            </p>
          </div>
        </div>
        <div
          class="mx-auto mt-3 col-lg-4 col-md-6 my-sm-auto ms-sm-auto me-sm-0"
        >
          <div class="nav-wrapper position-relative end-0">
            <ul
              class="p-1 bg-transparent nav nav-pills nav-fill"
              role="tablist"
            >
              <li class="nav-item" @click="changeMode('show')">
                <a
                  class="px-0 py-1 mb-0 nav-link active"
                  data-bs-toggle="tab"
                  href="javascript:;"
                  role="tab"
                  aria-selected="true"
                >
                  <svg
                    class="text-dark"
                    width="16px"
                    height="16px"
                    viewBox="0 0 42 42"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                  >
                    <g
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <g
                        transform="translate(-2319.000000, -291.000000)"
                        fill="#FFFFFF"
                        fill-rule="nonzero"
                      >
                        <g transform="translate(1716.000000, 291.000000)">
                          <g transform="translate(603.000000, 0.000000)">
                            <path
                              class="color-background"
                              d="M22.7597136,19.3090182 L38.8987031,11.2395234 C39.3926816,10.9925342 39.592906,10.3918611 39.3459167,9.89788265 C39.249157,9.70436312 39.0922432,9.5474453 38.8987261,9.45068056 L20.2741875,0.1378125 L20.2741875,0.1378125 C19.905375,-0.04725 19.469625,-0.04725 19.0995,0.1378125 L3.1011696,8.13815822 C2.60720568,8.38517662 2.40701679,8.98586148 2.6540352,9.4798254 C2.75080129,9.67332903 2.90771305,9.83023153 3.10122239,9.9269862 L21.8652864,19.3090182 C22.1468139,19.4497819 22.4781861,19.4497819 22.7597136,19.3090182 Z"
                            ></path>
                            <path
                              class="color-background"
                              d="M23.625,22.429159 L23.625,39.8805372 C23.625,40.4328219 24.0727153,40.8805372 24.625,40.8805372 C24.7802551,40.8805372 24.9333778,40.8443874 25.0722402,40.7749511 L41.2741875,32.673375 L41.2741875,32.673375 C41.719125,32.4515625 42,31.9974375 42,31.5 L42,14.241659 C42,13.6893742 41.5522847,13.241659 41,13.241659 C40.8447549,13.241659 40.6916418,13.2778041 40.5527864,13.3472318 L24.1777864,21.5347318 C23.8390024,21.7041238 23.625,22.0503869 23.625,22.429159 Z"
                              opacity="0.7"
                            ></path>
                            <path
                              class="color-background"
                              d="M20.4472136,21.5347318 L1.4472136,12.0347318 C0.953235098,11.7877425 0.352562058,11.9879669 0.105572809,12.4819454 C0.0361450918,12.6208008 6.47121774e-16,12.7739139 0,12.929159 L0,30.1875 L0,30.1875 C0,30.6849375 0.280875,31.1390625 0.7258125,31.3621875 L19.5528096,40.7750766 C20.0467945,41.0220531 20.6474623,40.8218132 20.8944388,40.3278283 C20.963859,40.1889789 21,40.0358742 21,39.8806379 L21,22.429159 C21,22.0503869 20.7859976,21.7041238 20.4472136,21.5347318 Z"
                              opacity="0.7"
                            ></path>
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                  <span class="ms-1">Главное</span>
                </a>
              </li>
              <li class="nav-item" @click="changeMode('edit')">
                <a
                  class="px-0 py-1 mb-0 nav-link"
                  data-bs-toggle="tab"
                  href="javascript:;"
                  role="tab"
                  aria-selected="false"
                >
                  <svg
                    class="text-dark"
                    width="16px"
                    height="16px"
                    viewBox="0 0 40 40"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                  >
                    <title>settings</title>
                    <g
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <g
                        transform="translate(-2020.000000, -442.000000)"
                        fill="#FFFFFF"
                        fill-rule="nonzero"
                      >
                        <g transform="translate(1716.000000, 291.000000)">
                          <g transform="translate(304.000000, 151.000000)">
                            <polygon
                              class="color-background"
                              opacity="0.596981957"
                              points="18.0883333 15.7316667 11.1783333 8.82166667 13.3333333 6.66666667 6.66666667 0 0 6.66666667 6.66666667 13.3333333 8.82166667 11.1783333 15.315 17.6716667"
                            ></polygon>
                            <path
                              class="color-background"
                              d="M31.5666667,23.2333333 C31.0516667,23.2933333 30.53,23.3333333 30,23.3333333 C29.4916667,23.3333333 28.9866667,23.3033333 28.48,23.245 L22.4116667,30.7433333 L29.9416667,38.2733333 C32.2433333,40.575 35.9733333,40.575 38.275,38.2733333 L38.275,38.2733333 C40.5766667,35.9716667 40.5766667,32.2416667 38.275,29.94 L31.5666667,23.2333333 Z"
                              opacity="0.596981957"
                            ></path>
                            <path
                              class="color-background"
                              d="M33.785,11.285 L28.715,6.215 L34.0616667,0.868333333 C32.82,0.315 31.4483333,0 30,0 C24.4766667,0 20,4.47666667 20,10 C20,10.99 20.1483333,11.9433333 20.4166667,12.8466667 L2.435,27.3966667 C0.95,28.7083333 0.0633333333,30.595 0.00333333333,32.5733333 C-0.0583333333,34.5533333 0.71,36.4916667 2.11,37.89 C3.47,39.2516667 5.27833333,40 7.20166667,40 C9.26666667,40 11.2366667,39.1133333 12.6033333,37.565 L27.1533333,19.5833333 C28.0566667,19.8516667 29.01,20 30,20 C35.5233333,20 40,15.5233333 40,10 C40,8.55166667 39.685,7.18 39.1316667,5.93666667 L33.785,11.285 Z"
                            ></path>
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                  <span class="ms-1">Редактировать</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="py-4 container-fluid" v-if="mode === 'show'">
    <div class="row mt">
      <div class="col-lg-3 mb-4">
        <cheap-card :cheap_number="pet.cheap_number" v-if="pet.cheap_number"></cheap-card>
        <cheap-setup-card :pet_id="pet.id" @update:setupCheapNumber="cheapNumber => pet.cheap_number = cheapNumber" v-else></cheap-setup-card>
      </div>
    </div>

      <div class="row mt-4">
          <div class="col-12">
              <div class="card">
                  <!-- Card header -->
                  <div class="pb-0 card-header">
                      <div class="d-lg-flex">
                          <div>
                              <h5 class="mb-0">История изменений веса</h5>
                          </div>
                          <div class="my-auto mt-4 ms-auto mt-lg-0" v-if="!measurements_failed">
                              <div class="my-auto ms-auto">
                                  <a @click="alert" class="mb-0 btn bg-gradient-success btn-sm">Подробнее</a>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="px-0 pb-0 card-body">
                      <div class="p-3 pt-0" v-if="measurements_failed">
                          История изменений недоступна
                      </div>
                      <div class="table-responsive measurements-table" v-else>
                          <table id="users-list" ref="usersList" class="table table-flush">
                              <thead class="thead-light">
                              <tr>
                                  <th>Вес</th>
                                  <th>Статус</th>
                                  <th>Дата</th>
                                  <th>Действия</th>
                              </tr>
                              </thead>
                              <tbody class="text-sm">
                              <tr v-for="measurement in measurements.measurements" v-bind:key="measurement.id">
                                  <td>
                                    <div v-if="measurement._changing">
                                    <input type="text" style="border: 1px solid #ccc; padding: 5px; border-radius: 2px; max-width: 80px" v-model:="measurement.value" v-bind:style="pet_weight_input_size">
                                      &nbsp;
                                    <span class="save-weight-button" @click="patchMeasurement(measurement);measurement._changing=false">
                                      <i class="fa fa-check"></i>
                                    </span>
                                    </div>
                                    <span v-else>{{ measurement.value }}</span>
                                  </td>
                                  <td>
                                      <span v-if="measurement.status === 1">
                                          <i class="fas fa-arrow-up"></i>
                                      </span>
                                      <span v-else-if="measurement.status === 2">
                                          <i class="fas fa-arrow-down"></i>
                                      </span>
                                  </td>
                                  <td>{{ measurement.measured_at }}</td>
                                  <td>
                                    <i class="fas fa-edit" style="cursor: pointer" title="Изменить" @click="measurement._changing=!measurement._changing"></i>
                                  </td>
                              </tr>
                              </tbody>
                          </table>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>

  <div class="py-4 container-fluid" v-else-if="mode === 'edit'">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <h5>Редактировать данные о питомце</h5>
          </div>

          <FamilyPetEditForm v-model:_pet="pet"></FamilyPetEditForm>
        </div>
      </div>
    </div>

    <FamilyPetDeleteForm :pet_id="pet.id"></FamilyPetDeleteForm>
  </div>
</template>

<script>
import {
  faFacebook,
  faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import showSwal from "/src/mixins/showSwal.js";
import FamilyPetEditForm from "@/views/components/family/pet/EditForm.vue";
import FamilyPetDeleteForm from "@/views/components/family/pet/DeleteForm.vue";
import CheapCard from "@/views/components/family/pet/CheapCard.vue";
import CheapSetupCard from "@/views/components/family/pet/CheapSetupCard.vue";

export default {
  name: "PetProfile",
  components: {CheapSetupCard, CheapCard, FamilyPetDeleteForm, FamilyPetEditForm},
  data() {
    return {
      mode: "show",
      showMenu: false,
      pet: {
          name: "Питомец",
      },
      measurements: [],
      measurements_failed: false,
      faFacebook,
      faTwitter,
      faInstagram,
    };
  },

  mounted() {
    this.$store.state.isAbsolute = true;
    setNavPills();
    setTooltip(this.$store.state.bootstrap);
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
  },

  computed: {
    breed_name: function () {
      return this.$store.getters["animals/breed_id_map"][this.pet.breed_id]?.name
    },
  },

  async created() {
      try {
          this.pet = await this.$store.dispatch("family/getPet", this.$route.params.pet_id);
          await this.$store.dispatch("animals/types");
      } catch (error) {
          showSwal.methods.showSwal({
              type: "error",
              message: "Что-то пошло не так",
          });
      }

      try {
          this.measurements = await this.$store.dispatch("petMeasurements/getMeasurements", this.$route.params.pet_id);
      } catch (error) {
          this.measurements_failed = true;
      }
  },

  methods: {
    async patchMeasurement(measurement) {
      try {
        await this.$store.dispatch("petMeasurements/patchMeasurement", {
          id: measurement.id,
          value: parseFloat(measurement.value),
        })

        showSwal.methods.showSwal({
          type: "success",
          message: "Запись успешно обновлена",
        });
      } catch (error) {
        console.log(error)

        showSwal.methods.showSwal({
          type: "error",
          message: "Что-то пошло не так",
        });
      }
    },

    changeMode(newMode) {
      this.mode = newMode
    },
  },
};
</script>

<style>
.measurements-table{
    max-height: 300px;
    overflow-y:scroll;
}
</style>
