<template>
  <multiselect
      :options="items"
      v-model="selectValue"
      label="title"
      track-by="value"
      :searchable="true"
      :preselect-first="true"
      tagPlaceholder="Нажмите, чтобы выбрать"
      deselectLabel="Нажмите, чтобы убрать"
      selectLabel="Нажмите, чтобы выбрать"
      selectedLabel="Выбрано"
      :placeholder="placeholder"
      class="mselect"
  ></multiselect>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
  name: "SoftSearchSelect",

  components: {
    Multiselect,
  },

  props: {
    items: {
      type: Array,
    },
    error: {
      type: Boolean,
      default: false,
    },
    modelValue: {
    },
    placeholder: {
      default: "Введите название или выберите из списка",
    }
  },
  emits: ["update:modelValue"],

  data() {
    return {
      "selectValue": null,
      "ownerValueChanging": false,
    }
  },

  mounted() {
    if (this.modelValue) {
      this.setupValue(this.modelValue)
    }
  },

  watch: {
    selectValue: function (newVal) {
      if (!this.ownerValueChanging) {
        this.$emit('update:modelValue', newVal.value);
        this.ownerValueChanging = false;
      }
    },

    modelValue: function (newVal) {
      this.setupValue(newVal)
    }
  },

  methods: {
    setupValue(value) {
      this.ownerValueChanging = true;

      for (const item of this.items) {
        if (item.value === value) {
          this.selectValue = item;
          break;
        }
      }

      this.ownerValueChanging = false;
    },
  },
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style>
.multiselect__tags {
  border: 1px solid #d2d6da;
  border-radius: 0.5rem
}
</style>
