import petMeasurementsService from "@/services/petMeasurements.service";

const initialState = { my_families: [] };

export const petMeasurements = {
    namespaced: true,
    state: initialState,
    actions: {
        getMeasurements(_, petId) {
            return petMeasurementsService.getMeasurements(petId, 1);
        },
        createMeasurement(_, payload) {
            return petMeasurementsService.createMeasurement(payload);
        },
        createWeightMeasurement(_, payload) {
            payload.subject = "weight"

            return petMeasurementsService.createMeasurement(payload);
        },
        patchMeasurement(_, payload) {
            return petMeasurementsService.patchMeasurement(payload.id, payload);
        },
    },
    mutations: {},
    getters: {},
};
