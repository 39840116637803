<template>
  <Field
      :id="id"
      :type="type"
      :name="name"
      :placeholder="placeholder"
      :is-required="isRequired"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"

      v-slot="{field, errors}"
  >
    <div class="form-group mb-0">
      <div class="input-group">
        <input
            :type="type"
            :name="name"
            class="form-control"
            :class="getClasses(size, success, errors.length > 0)"
            v-bind="field"
        >

        <input
            class="btn btn-outline-secondary"
            type="button"
            @click="btnClick()"
            :value="btnValue"
            style="margin-bottom:0"
            v-if="errors.length === 0"
        >

        <input
            class="btn btn-outline-secondary"
            type="button"
            :value="btnValue"
            style="margin-bottom:0"
            disabled="disabled"
            v-else
        >
      </div>

      <ErrorMessage
          :name="name"
          class="error-feedback text-xs text-danger"
          role="alert"
      />
    </div>
  </Field>
</template>

<script>
import { Field, ErrorMessage } from "vee-validate";
export default {
  name: "SoftFieldBtn",
  components: {
    Field,
    ErrorMessage,
  },
  props: {
    size: {
      type: String,
      default: "default",
    },
    success: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: "",
    },
    iconDir: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    modelValue: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "Type here...",
    },
    type: {
      type: String,
      default: "text",
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    btnClick: {
      type: Function,
    },
    btnValue: {
      type: String,
    },
  },
  emits: ["update:modelValue"],
  methods: {
    getClasses: (size, success, error) => {
      let sizeValue, isValidValue;

      sizeValue = size ? `form-control-${size}` : null;

      if (error) {
        isValidValue = "is-invalid";
      } else if (success) {
        isValidValue = "is-valid";
      } else {
        isValidValue = "";
      }

      return `${sizeValue} ${isValidValue}`;
    },
  },
};
</script>
