<template>
  <div id="password" class="card">
    <div class="card-header">
      <h5>Сменить пароль</h5>
    </div>

    <div class="card-body pt-0">
      <Form
          role="form"
          class="text-start"
          @submit="handlePassChange"
          :validation-schema="schema"
      >
      <div class="row mb-3">
        <label class="col-form-label-lg">Текущий пароль</label>
        <soft-field
            id="old_password"
            v-model="passChange.old_password"
            type="password"
            placeholder="********"
            name="old_password"
        />
      </div>

      <div class="row mb-3">
        <label class="col-form-label-lg">Новый пароль</label>
        <soft-field
            id="new_password"
            v-model="passChange.new_password"
            type="password"
            placeholder="********"
            name="new_password"
        />
      </div>

      <soft-button
          color="dark"
          variant="gradient"
          :is-disabled="loading"
          full-width
          size="lg"
      >
        <span v-if="loading" class="spinner-border spinner-border-sm"></span>
        <span v-else>Сменить</span>
      </soft-button>
      </Form>
    </div>
  </div>
</template>
<script>
import SoftButton from "@/components/SoftButton.vue";
import SoftField from "@/components/SoftField.vue";
import { Form } from "vee-validate";
import showSwal from "/src/mixins/showSwal.js";
import formMixin from "/src/mixins/form-mixin.js";
import * as yup from "yup";

export default {
  name: "ProfileChangePassword",

  mixins: [
      formMixin,
  ],

  components: {
    SoftButton,
    SoftField,
    Form,
  },

  data() {
    const schema = yup.object().shape({
      old_password: yup
          .string()
          .required("Укажите текущий пароль"),
      new_password: yup
          .string()
          .required("Укажите новый пароль")
          .min(8, "Пароль должен содержать не менее 8 символов"),
    });

    const passChange = {
      old_password: "",
      new_password: "",
    };

    return {
      passChange,
      schema,
      loading: false,
    }
  },

  methods: {
    async handlePassChange() {
      this.loading = true;

      try {
        await this.$store.dispatch("auth/changePassword", this.passChange);
        showSwal.methods.showSwal({
          type: "success",
          message: "Ваш пароль успешно изменен!",
          width: 500,
        });
        this.loading = false;
      } catch (error) {
        if (error.response.status === 422) {
          if (error.response.data && error.response.data.message === 'old_password_not_match') {
            showSwal.methods.showSwal({
              type: "error",
              message: "Указан неверный пароль",
            });
            return
          }
        }

        showSwal.methods.showSwal({
          type: "error",
          message: "Указаны некорректные данные",
        });
      } finally {
        this.loading = false;
      }
    },
  }
}
</script>