<template>
  <div class="card-body pt-0">
    <Form
        role="form"
        class="text-start"
        @submit="handleSave"
        :validation-schema="schema"
    >
      <div class="row mb-3">
        <label class="form-label">Номер чипа</label>

        <soft-field-btn
            id="cheap_number"
            name="cheap_number"
            v-model="pet.cheap_number"
            placeholder="123456789123456"
            class="mb-3"
            type="text"
            :btn-click="fillFormFromCheap"
            btn-value="Заполнить"
        ></soft-field-btn>
      </div>

      <div class="row mb-3">
        <label class="form-label">Имя</label>
        <soft-field
            id="name"
            name="name"
            v-model="pet.name"
            type="text"
            placeholder="Барсик"
        />
      </div>

      <div>
        <label class="form-label">Пол</label>

        <div class="form-check">
          <input
              class="form-check-input"
              type="radio"
              name="name"
              value=0
              v-model="pet.gender"
          />
          <label class="custom-control-label" for="gender">
            Самец
            <slot />
          </label>
        </div>

        <div class="form-check">
          <input
              class="form-check-input"
              type="radio"
              name="name"
              value=1
              v-model="pet.gender"
          />
          <label class="custom-control-label" for="gender">
            Самка
            <slot />
          </label>
        </div>

        <validation-error :errors="apiValidationErrors.gender" />
      </div>

      <div class="row mb-3">
        <label class="form-label">День рождения</label>
        <soft-model-input
            id="weight"
            v-model="pet.birth_date"
            type="date"
        />
        <validation-error :errors="apiValidationErrors.birth_date" />
      </div>

      <div class="row mb-3">
        <label class="form-label">Порода</label>

        <div class="form-group mb-0" v-if="breed_select.length > 0">
          <soft-search-select :model-value=pet.breed_id v-bind:items=breed_select @update:model-value="val => pet.breed_id = val"></soft-search-select>
        </div>
      </div>

      <div class="row mb-3" v-if="mode === 'create'">
        <label class="form-label">Вес</label>
        <soft-model-input
            id="weight"
            v-model="pet.weight"
            type="number"
            placeholder=10.0
        />
        <validation-error :errors="apiValidationErrors.weight" />
      </div>

      <soft-button
          class="float-end mt-3 mb-0"
          color="dark"
          variant="gradient"
          size="sm"
          :is-disabled="loading2 ? true : false"
          full-width
      >
        <span v-if="loading2" class="spinner-border spinner-border-sm"></span>
        <span v-else>Сохранить</span>
      </soft-button>
    </Form>
  </div>
</template>

<script>
import SoftButton from "@/components/SoftButton.vue";
import SoftModelInput from "@/components/SoftModelInput.vue";
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "/src/mixins/form-mixin.js";
import img from "/src/assets/img/bruce-mars.jpg";
import showSwal from "/src/mixins/showSwal.js";
import cheapFinderService from "@/services/cheapFinder.service";
import SoftSearchSelect from "@/components/SoftSearchSelect.vue";
import * as yup from "yup";
import { Form } from "vee-validate";
import SoftFieldBtn from "@/components/SoftFieldBtn.vue";
import SoftField from "@/components/SoftField.vue";

export default {
  name: "FamilyPetEditForm",
  components: {
    SoftFieldBtn,
    SoftSearchSelect,
    SoftButton,
    SoftModelInput,
    ValidationError,
    Form,
    SoftField,
  },
  mixins: [formMixin],
  props: {
    _pet: {
      type: Object,
    },
    mode: {
      type: String,
      default: "edit",
    },
  },
  data() {
    const schema = yup.object().shape({
      name: yup
          .string()
          .min(3, "Имя должно содержать не меньше 3 букв")
          .max(64, "Слишком длинное имя"),
      cheap_number: yup.string().nullable().notRequired().when('cheap_number', {
        is: value => value?.length,
        then: rule => rule
            .length(15, "Номер чипа должен содержать 15 цифр")
            .matches("^[0-9]*$", "Номер чипа должен содержать только цифры"),
      }),
    }, [
        ['cheap_number', 'cheap_number'],
    ]);

    return {
      pet: this._pet,
      loading: false,
      loading2: false,
      img,
      breed_list: [],
      breed_select: [],
      schema,
    };
  },
  computed: {
    loaded_breed_list() {
      return this.$store.getters["animals/breed_list"];
    },
  },
  async created() {
    try {
      this.loadBreeds()
    } catch (error) {
      console.log(error)
      showSwal.methods.showSwal({
        type: "error",
        message: "Что-то пошло не так",
      });
    }
  },

  methods: {
    async handleSave() {
      this.resetApiValidation();

      this.pet.gender = parseInt(this.pet.gender)
      this.pet.breed_id = parseInt(this.pet.breed_id)

      try {
        if (this.mode === "create") {
          await this.$store.dispatch("family/createPet", this.pet);
          showSwal.methods.showSwal({
            type: "success",
            message: "Питомец успешно добавлен",
            width: 600,
          });

          this.$router.push("/dashboard")
        } else {
          await this.$store.dispatch("family/patchPet", this.pet);
          showSwal.methods.showSwal({
            type: "success",
            message: "Данные о питомце успешно обновлены",
            width: 600,
          });
        }
      } catch (error) {
        showSwal.methods.showSwal({
          type: "error",
          message: error.response.data.message,
        });
      } finally {
        this.loading2 = false;
      }
    },

    async loadBreeds() {
      if (this.breed_list.length > 0) {
        return
      }

      await this.$store.dispatch("animals/types");
      this.breed_list = this.loaded_breed_list;

      this.breed_select = []

      for (let i = 0; i < this.breed_list.length; i++) {
        this.breed_select.push({
          "value": this.breed_list[i].id,
          "title": this.breed_list[i].name + " - " + this.breed_list[i].type.name,
        })
      }
    },

    async fillFormFromCheap() {
      if (this.pet.cheap_number.length !== 15) {
        return
      }

      try {
        const cheap = await cheapFinderService.findCheap(this.pet.cheap_number)

        this.pet.name = cheap.pet.name

        if (cheap.pet.breed_key) {
          let breedId = -1

          for (const breed of this.breed_list) {
            if (breed.key === cheap.pet.breed_key) {
              breedId = breed.id;
              break;
            }
          }

          this.pet.breed_id = breedId
        }

        if (cheap.pet.gender === 0 || cheap.pet.gender === 1) {
          this.pet.gender = cheap.pet.gender
        }
      } catch (error) {
        if (error.response.status === 400 || error.response.status === 422) {
          showSwal.methods.showSwal({
            type: "error",
            message: "Указан некорректный номер чипа",
          });
        } else {
          showSwal.methods.showSwal({
            type: "error",
            message: "Что-то пошло не так",
          });
        }
      }
    },
  },
};
</script>