<template>
  <div id="private-data" class="card">
    <div class="card-header">
      <h5>Персональные данные</h5>
    </div>

    <div class="card-body pt-0">
      <Form
          role="form"
          class="text-start"
          @submit="handleProfilePatch"
          :validation-schema="schema"
      >
        <div class="row mb-3">
          <label class="col-form-label-lg">Имя</label>
          <soft-field
              id="first_name"
              v-model="profilePatch.first_name"
              type="string"
              placeholder="Иван"
              name="first_name"
          />
        </div>

        <div class="row mb-3">
          <label class="col-form-label-lg">Фамилия</label>
          <soft-field
              id="last_name"
              v-model="profilePatch.last_name"
              type="string"
              placeholder="Иванов"
              name="last_name"
          />
        </div>

        <soft-button
            color="dark"
            variant="gradient"
            :is-disabled="loading"
            full-width
            size="lg"
        >
          <span v-if="loading" class="spinner-border spinner-border-sm"></span>
          <span v-else>Обновить</span>
        </soft-button>
      </Form>
    </div>
  </div>
</template>
<script>
import SoftButton from "@/components/SoftButton.vue";
import SoftField from "@/components/SoftField.vue";
import { Form } from "vee-validate";
import showSwal from "/src/mixins/showSwal.js";
import formMixin from "/src/mixins/form-mixin.js";
import * as yup from "yup";

export default {
  name: "ProfilePatch",

  mixins: [
    formMixin,
  ],

  components: {
    SoftButton,
    SoftField,
    Form,
  },

  props: {
    profile: {
      type: Object,
    },
  },

  data() {
    const schema = yup.object().shape({
      first_name: yup
          .string()
          .required("Укажите имя")
          .min(1, "Имя должно содержать не менее 1 символа"),
      last_name: yup
          .string()
          .min(1, "Фамилия должна содержать не менее 8 символов"),
    });

    return {
      schema,
      loading: false,
      profilePatch: {
        first_name: "",
        last_name: null,
      },
    }
  },

  created() {
    this.profilePatch = this.profile ?? {}
  },

  methods: {
    async handleProfilePatch() {
      this.loading = true;

      try {
        await this.$store.dispatch("auth/patchUser", this.profilePatch);
        showSwal.methods.showSwal({
          type: "success",
          message: "Данные успешно обновлены",
          width: 500,
        });
        this.loading = false;
      } catch (error) {
        showSwal.methods.showSwal({
          type: "error",
          message: "Указаны некорректные данные",
        });
      } finally {
        this.loading = false;
      }
    },
  },
}
</script>
