<template>
  <div class="container-fluid mt-4">

    <div class="row">
      <div class="col-12">
        <profile-patch :profile="profile"></profile-patch>
      </div>
    </div>

    <div class="row">
      &nbsp;
    </div>

    <div class="row">
      <div class="col-12">
        <profile-change-password></profile-change-password>
      </div>
    </div>

  </div>
</template>

<script>
// import SoftButton from "@/components/SoftButton.vue";
// import SoftSwitch from "@/components/SoftSwitch.vue";
// import SoftAvatar from "@/components/SoftAvatar.vue";
// import SoftModelInput from "@/components/SoftModelInput.vue";
// import ValidationError from "@/components/ValidationError.vue";
import formMixin from "/src/mixins/form-mixin.js";
import img from "/src/assets/img/bruce-mars.jpg";
import ProfileChangePassword from "@/views/components/ProfileChangePassword.vue";
import ProfilePatch from "@/views/components/auth/users/ProfilePatch.vue";

export default {
  name: "Profile",
  components: {
    ProfileChangePassword,
    // SoftButton,
    // SoftSwitch,
    // SoftAvatar,
    // SoftModelInput,
    // ValidationError,
    ProfilePatch,
  },
  mixins: [formMixin],
  data() {
    return {
      loading: false,
      loading2: false,
      img,
    };
  },
  computed: {
    profile() {
      return this.$store.getters["auth/currentUser"];
    },
    pic() {
      if (this.profile.profile_image) return this.profile.profile_image;
      else return require("/src/assets/img/placeholder.jpg");
    },
  },
  async created() {
  },
};
</script>
<style>
img {
  object-fit: cover;
}
</style>
