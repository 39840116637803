import animalsService from "../services/animals.service";

const initialState = {
  "types": [],
  "breed_id_map": {},
  "breed_list": [],
};

export const animals = {
  namespaced: true,
  state: initialState,
  actions: {
    async types({ commit, state }, userData) {
      if (state.breed_list.length > 0) {
        console.log("breed list already loaded")
      } else {
        const response = await animalsService.types(userData);
        commit("SET_TYPES", response.types);
      }
    },
  },
  mutations: {
    SET_TYPES(state, types) {
      state.types = types;
      state.breed_list = []

      for (let t = 0; t < types.length; t++) {
        for (let b = 0; b < types[t].breeds.length; b++) {
          const breed = types[t].breeds[b];

          state.breed_id_map[breed.id] = {
            ...breed,
            "type": types[t],
          }

          state.breed_list.push({
            ...breed,
            "type": types[t],
          })
        }
      }
    },
  },

  getters: {
    breed_id_map(state) {
      return state.breed_id_map;
    },
    breed_list(state) {
      return state.breed_list;
    },
  },
};
