import axios from "axios";

const API_URL = process.env.VUE_APP_API_BASE_URL + '/web';

export default {
  async types() {
    const response = await axios.get(API_URL + "/animals/types");

    return response.data
  },
};
