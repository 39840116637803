<template>
  <div class="form-group mb-0">
    <div :class="hasIcon(icon)">
      <span v-if="iconDir === 'left'" class="input-group-text">
        <i :class="getIcon(icon)"></i>
      </span>
      <Field
        :id="id"
        :type="type"
        :name="name"
        :is-required="isRequired"
        @input="$emit('update:modelValue', $event.target.value)"
        v-slot="{field, errors}"
      >
        <input
            :type="type"
            class="form-control"
            :class="getClasses(size, success, errors.length > 0)"
            v-bind="field"
            :value="modelValue"
            :placeholder="placeholder"
        >

        <ErrorMessage
          :name="name"
          class="error-feedback text-xs text-danger"
          role="alert"
        />
        <br v-if="errors.length > 0" />
        <span v-if="iconDir === 'right'" class="input-group-text">
          <i :class="getIcon(icon)"></i>
        </span>
      </Field>
    </div>
  </div>
</template>

<script>
import { Field, ErrorMessage } from "vee-validate";
export default {
  name: "SoftInput",
  components: {
    Field,
    ErrorMessage,
  },
  props: {
    size: {
      type: String,
      default: "default",
    },
    success: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: "",
    },
    iconDir: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    modelValue: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "Type here...",
    },
    type: {
      type: String,
      default: "text",
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue"],
  methods: {
    getClasses: (size, success, error) => {
      let sizeValue, isValidValue;

      sizeValue = size ? `form-control-${size}` : null;

      if (error) {
        isValidValue = "is-invalid";
      } else if (success) {
        isValidValue = "is-valid";
      } else {
        isValidValue = "";
      }

      return `${sizeValue} ${isValidValue}`;
    },
    getIcon: (icon) => (icon ? icon : null),
    hasIcon: (icon) => (icon ? "input-group" : null),
  },
};
</script>
