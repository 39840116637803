<template>
  <nav
    class="navbar fixed-top navbar-main navbar-expand-lg navbar-light bg-light"
    style="box-shadow: none;background: #fff;border-bottom: 1px solid #ccc"
    data-scroll="true"
  >
    <i
        class="top-0 p-3 cursor-pointer fas fa-times text-secondary position-absolute end-0 d-none d-xl-none"
        aria-hidden="true"
        id="iconSidenav"
    ></i>
    <a class="m-0 navbar-brand" href="/">
      <img :src="logo" class="navbar-brand-img h-100" alt="main_logo" width="32px" height="32px" />
      <span class="ms-1 font-weight-bolder-md">Мой Питомец</span>
    </a>
    &nbsp;&nbsp;
    <a
        href="#"
        @click="toggleSidebar"
        id="iconNavbarSidenav"
    >
      <div class="sidenav-toggler-inner">
        <i class="sidenav-toggler-line"></i>
        <i class="sidenav-toggler-line"></i>
        <i class="sidenav-toggler-line"></i>
      </div>
    </a>

      <div class="mt-2 collapse navbar-collapse mt-sm-0 me-md-0 me-sm-4">
        <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
        </ul>
        <div
            class="pe-md-3 d-flex align-items-center ms-md-auto"
        >
        </div>
        <div>
          <ul class="navbar-nav navbar-right my-2 my-lg-0">
          <li class="px-3 nav-item d-flex align-items-center">
            <a
              class="p-0 nav-link"
              @click="toggleConfigurator"
            >
              <i class="cursor-pointer fa fa-cog fixed-plugin-button-nav"></i>
            </a>
          </li>
          <li
            class="nav-item dropdown d-flex align-items-center pe-3"
          >
            <a
              href="#"
              class="p-0 nav-link"
              :class="[
                showMenu ? 'show' : '',
              ]"
              id="dropdownMenuButton"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              @click="showMenu = !showMenu"
            >
              <i class="cursor-pointer fa fa-bell"></i>
            </a>
            <ul
              class="px-2 py-3 dropdown-menu dropdown-menu-end me-sm-n4"
              :class="showMenu ? 'show' : ''"
              aria-labelledby="dropdownMenuButton"
            >
              <li class="mb-2">
                <a class="dropdown-item border-radius-md" href="javascript:;">
                  <div class="py-1 d-flex">
                    <div class="my-auto">
                      <img
                        src="../../assets/img/team-2.jpg"
                        class="avatar avatar-sm me-3"
                        alt="user image"
                      />
                    </div>
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-1 text-sm font-weight-normal">
                        <span class="font-weight-bold">New message</span> from
                        Laur
                      </h6>
                      <p class="mb-0 text-xs text-secondary">
                        <i class="fa fa-clock me-1"></i>
                        13 minutes ago
                      </p>
                    </div>
                  </div>
                </a>
              </li>
              <li class="mb-2">
                <a class="dropdown-item border-radius-md" href="javascript:;">
                  <div class="py-1 d-flex">
                    <div class="my-auto">
                      <img
                        src="../../assets/img/small-logos/logo-spotify.svg"
                        class="avatar avatar-sm bg-gradient-dark me-3"
                        alt="logo spotify"
                      />
                    </div>
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-1 text-sm font-weight-normal">
                        <span class="font-weight-bold">New album</span> by
                        Travis Scott
                      </h6>
                      <p class="mb-0 text-xs text-secondary">
                        <i class="fa fa-clock me-1"></i>
                        1 day
                      </p>
                    </div>
                  </div>
                </a>
              </li>
              <li>
                <a class="dropdown-item border-radius-md" href="javascript:;">
                  <div class="py-1 d-flex">
                    <div
                      class="my-auto avatar avatar-sm bg-gradient-secondary me-3"
                    >
                      <svg
                        width="12px"
                        height="12px"
                        viewBox="0 0 43 36"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                      >
                        <title>credit-card</title>
                        <g
                          stroke="none"
                          stroke-width="1"
                          fill="none"
                          fill-rule="evenodd"
                        >
                          <g
                            transform="translate(-2169.000000, -745.000000)"
                            fill="#FFFFFF"
                            fill-rule="nonzero"
                          >
                            <g transform="translate(1716.000000, 291.000000)">
                              <g transform="translate(453.000000, 454.000000)">
                                <path
                                  class="color-background"
                                  d="M43,10.7482083 L43,3.58333333 C43,1.60354167 41.3964583,0 39.4166667,0 L3.58333333,0 C1.60354167,0 0,1.60354167 0,3.58333333 L0,10.7482083 L43,10.7482083 Z"
                                  opacity="0.593633743"
                                ></path>
                                <path
                                  class="color-background"
                                  d="M0,16.125 L0,32.25 C0,34.2297917 1.60354167,35.8333333 3.58333333,35.8333333 L39.4166667,35.8333333 C41.3964583,35.8333333 43,34.2297917 43,32.25 L43,16.125 L0,16.125 Z M19.7083333,26.875 L7.16666667,26.875 L7.16666667,23.2916667 L19.7083333,23.2916667 L19.7083333,26.875 Z M35.8333333,26.875 L28.6666667,26.875 L28.6666667,23.2916667 L35.8333333,23.2916667 L35.8333333,26.875 Z"
                                ></path>
                              </g>
                            </g>
                          </g>
                        </g>
                      </svg>
                    </div>
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-1 text-sm font-weight-normal">
                        Payment successfully completed
                      </h6>
                      <p class="mb-0 text-xs text-secondary">
                        <i class="fa fa-clock me-1"></i>
                        2 days
                      </p>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </li>
          <li class="nav-item d-flex align-items-center log-button">
            <router-link
                v-if="!loggedIn"
                :to="{ name: 'Login' }"
                class="px-0 nav-link font-weight-bold"
            >
              Войти
            </router-link>

            <a
                v-else
                class="px-0 nav-link font-weight-bold"
                style="cursor: pointer"
                @click="logoutUser"
            >
              Выйти
            </a>
          </li>
        </ul>
        </div>
    </div>

  </nav>


</template>
<script>
import { mapMutations, mapActions } from "vuex";
import logo from "@/assets/img/logo-ct.png";

export default {
  name: "navbar",
  data() {
    return {
      logo,
      showMenu: false,
    };
  },
  props: ["minNav", "textWhite"],
  created() {
    this.minNav;
  },
  methods: {
    ...mapMutations(["navbarMinimize", "toggleConfigurator"]),
    ...mapActions(["toggleSidebarColor"]),

    toggleSidebar() {
      this.toggleSidebarColor("bg-white");
      this.navbarMinimize();
    },

    async logoutUser() {
      try {
        await this.$store.dispatch("auth/logout");
      } finally {
        this.$router.push("/login");
      }
    },
  },
  components: {
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    loggedIn() {
      return this.$store.getters["auth/authenticated"];
    },
  },
  updated() {
    const navbar = document.getElementById("navbarBlur");
    window.addEventListener("scroll", () => {
      if (window.scrollY > 10 && this.$store.state.isNavFixed) {
        navbar.classList.add("blur");
        navbar.classList.add("position-sticky");
        navbar.classList.add("shadow-blur");
      } else {
        navbar.classList.remove("blur");
        navbar.classList.remove("position-sticky");
        navbar.classList.remove("shadow-blur");
      }
    });
  },
};
</script>
<style>
a:hover {
  cursor: pointer;
}
.log-button {
  cursor: pointer;
  background: #e9ecef;
  padding: 0 25px;
  border-radius: 15px;
}
.log-button:hover {
  background: #dee2e6;
}
.navbar-right {
  float:right!important;
}
</style>