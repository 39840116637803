<template>
  <navbar
      :textWhite="this.$store.state.isAbsolute ? 'text-white opacity-8' : ''"
      :minNav="navbarMinimize"
      v-if="this.$store.state.showNavbar"
  />

  <sidenav
      :class="[
      this.$store.state.isTransparent,
      'fixed-start',
    ]"
      v-if="this.$store.state.showSidenav"
      style="top: 62px;"
  />

  <div style="height: 70px" v-if="this.$store.state.showSidenav"></div>
<!--  @todo костыль для топбара-->

  <main
    class="main-content position-relative max-height-vh-100 h-100 border-radius-lg"
  >
    <nav
        class="shadow-none navbar navbar-main navbar-expand-lg border-radius-xl"
        v-bind="$attrs"
        id="navbarBlur"
        data-scroll="true"
        v-if="this.$store.state.showNavbar"
    >
    <div class="px-3 py-1 container-fluid">
    <breadcrumbs :currentPage="currentRouteName" :textWhite="textWhite" />
    </div>
    </nav>

    <router-view />
    <app-footer v-show="this.$store.state.showFooter" />
  </main>
</template>
<script>
import Sidenav from "./examples/Sidenav";
import Navbar from "@/examples/Navbars/Navbar.vue";
import AppFooter from "@/examples/Footer.vue";
import { mapMutations } from "vuex";
import Breadcrumbs from "@/examples/Breadcrumbs.vue";
export default {
  name: "App",
  components: {
    Breadcrumbs,
    Sidenav,
    Navbar,
    AppFooter,
  },
  methods: {
    ...mapMutations(["toggleConfigurator", "navbarMinimize"]),
  },
  computed: {
    navClasses() {
      return {
        "position-sticky blur shadow-blur mt-4 left-auto top-1 z-index-sticky": this
          .$store.state.isNavFixed,
        "position-absolute px-4 mx-0 w-100 z-index-2": this.$store.state
          .isAbsolute,
        "px-0 mx-4 mt-4": !this.$store.state.isAbsolute,
      };
    },
    currentRouteName() {
      return this.$route.name;
    },
  },
  beforeMount() {
    this.$store.state.isTransparent = "bg-transparent";
  },
};
</script>
